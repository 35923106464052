import "./polyfill.scrollto.js";
import "regenerator-runtime";
import "@webcomponents/custom-elements";
import "@webcomponents/shadydom";
import "./custom-elements-es5-adapter.js";

// load the polyfills for non es6 supported browsers
let polyfillNomodule = document.createElement("script");
polyfillNomodule.src = "/components/rg-polyfill-nomodule.js";
polyfillNomodule.noModule = true;
document.getElementsByTagName('head')[0].appendChild(polyfillNomodule);